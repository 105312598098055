@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-image: url(./assets/images/site-bg.jpg);
  background-size: cover;
  background-position: center;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.speedy-font {
  font-family: "Speedy Maker Regular" !important;
}

@font-face {
  font-family: "Speedy Marker Regular";
  src: url(assets/font/speedymarkerregular.ttf);
}

.navbar {
  /* width: 100%; */
  top: 0px;
  left: 0px;
  height: 70px;
  border-style: none;
  border-width: 0px;
  color: #000000;
  -webkit-box-shadow: -0 5px 25px rgba(0, 0, 0, 1);
  -moz-box-shadow: -0 5px 25px rgba(0, 0, 0, 1);
  box-shadow: -0 5px 25px rgba(0, 0, 0, 1);
  /* background-color: #cb9346; */
  background-image: url("./assets/images/wood.jpg");
  background-size: 100% 100%;
  background-repeat: repeat-x;
  background-position: left top;
  padding: 0px 20px;
  z-index: 99;
}

.header_logo {
  width: 318px;
  height: 90px;
  text-decoration: none;
  background-repeat: no-repeat;
  background-image: url(./assets/images/logo.png);
  cursor: pointer;
  background-size: contain;
}

.mystrey_button {
  width: 160px;
  cursor: pointer;
  height: 90px;
  background-image: url(assets/images/mysterybox_desktop.png);
  background-position: 0px 0px;
  background-repeat: no-repeat;
}

.mystrey_button:hover {
  background-image: url(assets/images/mysterybox_hover.png);
}

.lure_button {
  display: inline-block;
  width: 150px;
  /*228px*/
  height: 100px;
  /*150px*/
  background-image: url("./assets/images/button-lure-about_desktop.png");
  background-position: 0px 0px;
  background-repeat: no-repeat;
  cursor: pointer;
}

.lure_button:hover {
  background-image: url("./assets/images/button-lure-about_hover.png");
}

.phone_button {
  display: inline-block;
  margin: 0px 0px;
  padding: 0px 0px;
  width: 190px;
  /*205px*/
  height: 76px;
  /*86px*/
  cursor: pointer;

  font-size: 28pt;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  line-height: 100%;

  background-image: url("./assets/images/phone_desktop.png");
  background-position: 0px 0px;
  background-repeat: no-repeat;
}

.phone_button:hover {
  background-image: url("./assets/images/phone_hover.png");
}

.store_button {
  display: inline-block;
  width: 182px;
  /*220px*/
  cursor: pointer;
  height: 65px;
  /*72px*/
  background-image: url("./assets/images/button-lure-store_desktop.png");
  background-position: 0px 0px;
  background-repeat: no-repeat;
}

.store_button:hover {
  background-image: url("./assets/images/button-lure-store_hover.png");
}

.social-pole {
  height: 100%;
  width: 35px;
  object-fit: cover;
  left: 41.5%;
  opacity: 1;
}

.twitterfollow {
  width: 250px;
  /*270px, 220px*/
  height: 60px;
  /*64px, 70px*/
  background-image: url(assets/images/entry_title_header/TomsSocial_1.png);
  background-size: 100% 100%;
}

.twitterfollow:hover {
  background-image: url(assets/images/entry_title_header/TomsSocial_1_hover.png);
}

.facebookfollow {
  width: 250px;
  /*270px, 220px*/
  height: 60px;
  /*64px, 70px*/
  background-image: url(assets/images/entry_title_header/TomsSocial_2.png);
  background-size: 100% 100%;
}

.facebookfollow:hover {
  background-image: url(assets/images/entry_title_header/TomsSocial_2_hover.png);
}

.entry-form {
  background-image: url(assets/images/form_wood.jpg);
  margin-top: 80px;
  padding-bottom: 100px;
  box-shadow: 0 0 20px 2px #000000;
}

.entry-form .enter-here {
  width: 105%;
}

.photo-upload {
  background-image: url(assets/images/entry/button-photo-b-1.png);
  background-size: 100% 100%;
  width: 190px;
  height: 130px;
  cursor: pointer;
}

.photo-upload:hover {
  background-image: url(assets/images/entry/button-photo-b-2.png);
}

.photo-upload:active {
  background-image: url(assets/images/entry/button-photo-b-3.png);
}

.post-card {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.card-twitter-icon,
.card-facebook-icon {
  background-size: 100% 100%;
  width: 20px;
  height: 20px;
}

.card-facebook-icon {
  background-image: url(assets/images/icons/facebook.png);
}

.card-twitter-icon {
  background-image: url(assets/images/icons/twitter.png);
}

.card-facebook-icon:hover {
  background-image: url(assets/images/icons/facebook_hover.png);
}

.card-twitter-icon:hover {
  background-image: url(assets/images/icons/twitter_hover.png);
}

.entry-sign {
  padding-bottom: 10px;
  font-family: "Speedy Marker Regular", Helvetica, Arial, sans-serif !important;
  font-size: 18pt;
  font-weight: bold;
  text-decoration: none;
  line-height: 100%;
  text-align: right;
}

.entry-item-id {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-size: 12pt;
  font-style: italic;
  color: #000000;
}

.select-lure {
  height: 50px;
  background-image: url(assets/images/lureSelect/lureSelect-button_default.png);
  background-size: 100% 100%;
}

.select-lure:hover {
  background-image: url(assets/images/lureSelect/lureSelect-button_hover.png);
}

.select-lure:active {
  background-image: url(assets/images/lureSelect/lureSelect-button-active.png);
}

.ReactModal__Overlay {
  z-index: 999;
}

.ReactModal__Content {
  padding: 0 !important;
}

.admin-entry-masonry div {
  align-items: center;
}

@media (max-width: 650px) {
  .ReactModal__Content {
    width: 95%;
  }
}

.admin-dashboard {
  background-image: url(assets/images/admin/bg.jpg);
  background-size: 100% 100%;
  width: 100vw;
  min-height: 100vh;
}

@media (max-width: 576px) {
  .entry-form {
    position: relative;
    top: 50px;
    padding-top: 50px;
    width: 100%;
  }

  .entry-form .enter-here {
    width: 110%;
    max-width: 110%;
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
  }
}

html {
  scroll-behavior: smooth;
}

.post-card-lure-img {
  transition: 0.3s ease;
}

.post-card-lure-img:hover {
  transform: rotate(-5deg);
}

.content-after{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ReactModal__Overlay>div{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: none !important;
}

@media (max-width: 620px) {
  .ReactModal__Content {
    height: 100% !important;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .ReactModal__Content::-webkit-scrollbar {
    display: none;
  }
}