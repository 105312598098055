.lure-color-serach {
  background-image: url(../../assets/images/lureSelect/lureSelect-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.lure-color-search-section {
  border-bottom: 1px solid grey;
}

.color-border {
  border: 4px solid #00000000;
  padding: 3px;
}

.color-border.color-item-clicked {
  border: 4px solid #000000;
}